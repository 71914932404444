import React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import CirclePhone from "../Icons/CirclePhone"
import PrimaryButton from "../Button/PrimaryButton"
import BreakpointUp from "../../components/Media/BreakpointUp"

const GridWrap = styled.div`
  position: relative;
  .gatsby-image-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .container {
    background: rgba(0, 89, 178, 0.92);
    padding: 40px 0;
    max-width: 100%;
    ${BreakpointUp.md`
      padding:60px 0;
    `}
    ${BreakpointUp.xl`
      padding:100px 0;
    `}
    ${BreakpointUp.xxl`
      padding:130px 0;
    `}
  }
`
const GridRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  justify-content: space-between;
  align-items: center;
  max-width: 1350px;
  margin: auto;
`
const GridLeft = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  ${BreakpointUp.lg`
		flex: 0 0 66.666667%;
    	max-width: 66.666667%;
	`}
  h2{
    font-size: 16px;
    line-height: 28px;
    color: #666;
    font-weight: 600;
    font-family: "UniNeueBook", sans-serif;
    margin:0;
  }
  label{
    display:block;
    font-family: 'UniNeueBold', sans-serif;
    font-weight: 700;
    margin:0 0 15px; 
    font-size: 24px;
    line-height: 32px;
    @media (min-width: 768px) {
      font-size: 32px;
      line-height: 44px;
    }
    @media (min-width: 992px) {
      font-size: 44px;
      line-height: 58px;
    }
  }
  & label, & h2 {
    color: #fff;
  }
`
const GridRight = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  ${BreakpointUp.lg`
		flex: 0 0 auto;
		width: auto;
		max-width: 100%;
	`}
  .btn {
    > .icon {
      & svg {
        height: 24px;
        width: 24px;
        ${BreakpointUp.xxl`
					height: 34px;
					width: 34px;
				`}
      }
    }
    ${BreakpointUp.xxl`
			font-size:32px;
			line-height:44px;
			padding:25px 40px;
		`}
  }
`

class CallToAction extends React.Component {
  render() {
    const { data } = this.props
    return (
      <GridWrap>
        <StaticImage src="../../images/cta-bg.png" alt="AES" />
        <div className="container">
          <GridRow>
            <GridLeft>
              <h2>{data.label}</h2>
              <label>{data.title}</label>
            </GridLeft>
            <GridRight>
              <a href="tel:8663861001" id="call">
                <PrimaryButton
                  size="50"
                  textAfter={data.linkText}
                  icon={<CirclePhone />}
                />
              </a>
            </GridRight>
          </GridRow>
        </div>
      </GridWrap>
    )
  }
}

export default CallToAction
