import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import ContentRightForm from "../ContentRightForm"
import VideoBlock from "../VideoBlock"
import PrimaryButton from "../Button/PrimaryButton"
import MVVideoModal from "../MVVideoModal"
import {MarkdownContent} from "../SectionTags"

const Wrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin:0 -15px;
  display: flex;
  padding: 0 0 40px 0;
  @media (min-width: 992px) {
    padding: 0 0 90px 0;
  }
`
const LeftWrap = styled.div`
  position: relative;
  width: 100%;
  padding:0 15px;
  @media (min-width: 992px) {
    width: 66.666667%;
  }
`
const RightWrap = styled.div`
  position: relative;
  width: 100%;
  padding:0 15px;
  @media (min-width: 992px) {
    width: 33.333333%;    
  }
`
const IsSticky = styled.div`
  position: relative;

  @media (min-width: 992px) {
    max-width: 100%;
    width: 100%;
    position: sticky;
    z-index: 1;
    top: 120px;
  }
`
const VideoWrap = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  border-radius: 8px;
  margin-top: 60px;	
  
  @media (min-width: 1600px){
    margin-top: 90px;
  }
  & video {
    width: 100%;
    border-radius: 8px;
  }
`
const BtnToolbar = styled.div`
  position:relative;
  bottom: 24px;
  @media (min-width: 768px) {
    bottom: 29px;
  }
  a {
    max-width: 330px;
    width: 100%;
    margin: 0 auto;
    display: block;
    .btn {
      display: block;
    }
  }
`

class Content extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isVideoModalOpen: false,
      currentVideo: { src: "", type: "" },
    }
    this.handleVideo = this.handleVideo.bind(this)
    this.openVideoModal = this.openVideoModal.bind(this)
    this.closeVideoModal = this.closeVideoModal.bind(this)
  }
  playVideo() {
    this.refs.vidRef.play()
  }
  openVideoModal(url) {
    this.setState({
      currentVideo: {
        type: "video/youtube",
        src: url,
      },
      isVideoModalOpen: true,
    })
  }
  closeVideoModal() {
    this.setState({ isVideoModalOpen: false })
  }
  handleVideo(url) {
    this.openVideoModal(url)
  }
  render() {
    const { data, video } = this.props
    return (
      <div className="container">
        <MVVideoModal
          isOpen={this.state.isVideoModalOpen}
          autoPlay
          src={this.state.currentVideo.src}
          type={this.state.currentVideo.type}
          controls
          onClose={this.closeVideoModal}
        />
        <Wrap>
          <LeftWrap>
            <MarkdownContent
              dangerouslySetInnerHTML={{
                __html: data.childMarkdownRemark.html,
              }}
            />
          </LeftWrap>
          <RightWrap>
            <IsSticky>
              <ContentRightForm />
              <VideoWrap>
                <VideoBlock
                  action={() => this.handleVideo(video)}
                  video={video}
                />
                <BtnToolbar>
                  <Link to="/about"><PrimaryButton text="Explore More About AES" /></Link>
                </BtnToolbar>
              </VideoWrap>
            </IsSticky>
          </RightWrap>
        </Wrap>
      </div>
    )
  }
}

export default Content
