import React from "react"
import styled from "styled-components"
import { BaseButton } from '../SectionTags'

const PrimaryBtn = styled(BaseButton)`
  background-color: #fab702;
  color: #fff;
  & .icon {
    > svg {
      fill: #fff;
    }
  }
  &:hover {
    background-color: #e8a900;
  }
`

const PrimaryButton = (props) => {
  const { icon, text, size, textAfter } = props
  return (
    <PrimaryBtn className={`btn ${size ? `btn-${size}` : ""} `}>
      {textAfter ? (
        <>
          {icon && <span className="icon">{icon}</span>}
          {textAfter && <span className="text">{textAfter}</span>}
        </>
      ) : (
        <>
          {text && <span className="text">{text}</span>}
          {icon && <span className="icon">{icon}</span>}
        </>
      )}
    </PrimaryBtn>
  )
}

export default PrimaryButton
