import React from "react";
import styled from 'styled-components';
import { RightSidebar } from "../SectionTags"

const FormWrap = styled.div`
  iframe {
    border: 0;
    width: 100%;
  }
`

const ContentRightForm = () => (
  <RightSidebar>
    <FormWrap>
      <iframe src="https://www.cognitoforms.com/f/7qxSu5WtzEeoHIw8Fz5hfw/2" title="Cognito Forms"></iframe>
    </FormWrap>
  </RightSidebar>
);

export default ContentRightForm
