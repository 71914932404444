import React from "react"

const CirclePhone = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="36"
    viewBox="0 0 36 36"
  >
    <g>
      <g
        transform="translate(-457 -883) translate(457 883)"
        stroke="#fff"
        strokeWidth="3"
        fill="none"
      >
        <circle cx="18" cy="18" r="18" stroke="none" />
        <circle cx="18" cy="18" r="16.5" />
      </g>
      <path
        d="M12.473 55.411a1.149 1.149 0 0 0-1.737 0c-.406.4-.812.805-1.211 1.215a.239.239 0 0 1-.334.061c-.263-.143-.543-.259-.8-.416a12.6 12.6 0 0 1-3.037-2.764 7.2 7.2 0 0 1-1.089-1.744.25.25 0 0 1 .061-.321c.406-.392.8-.8 1.2-1.2a1.153 1.153 0 0 0 0-1.778l-.952-.955s-.652-.659-.983-.983a1.156 1.156 0 0 0-1.737 0c-.409.4-.8.816-1.218 1.211a1.977 1.977 0 0 0-.621 1.334 5.653 5.653 0 0 0 .437 2.433 14.784 14.784 0 0 0 2.624 4.371 16.238 16.238 0 0 0 5.378 4.207 7.778 7.778 0 0 0 2.979.867 2.183 2.183 0 0 0 1.873-.713c.348-.389.74-.744 1.109-1.116a1.161 1.161 0 0 0 .007-1.768q-.97-.97-1.949-1.941z"
        transform="translate(10.582 -35.549)"
        fill="#fff"
      />
    </g>
  </svg>
)

export default CirclePhone
